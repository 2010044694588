<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		<div class="member-info" v-loading="loading">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="企业认证" name="first">
					<el-form :model="formData" :rules="rules" ref="ruleForm" label-width="100px"
						v-if="authStatus==0||authStatus==-1">
						<el-form-item label="拒绝原因">
							<p>{{ formData.reason }}</p>
						</el-form-item>
						<el-form-item label="类型" prop="type">
							<el-radio-group v-model="formData.type">
								<el-radio :label="1">物资类企业</el-radio>
								<el-radio :label="2">物流类企业</el-radio>
								<el-radio :label="3">服务类企业</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="所属地区" prop="area_name">
							<el-select v-model="formData.area_id" placeholder="请选择省"
								@change="changeArea(formData.area_id)">
								<el-option v-for="option in province" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="企业名称" prop="name">
							<el-input v-model="formData.name" placeholder="请输入企业名称" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="统一代码" prop="unicode">
							<el-input v-model="formData.unicode" placeholder="请输入统一代码" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="营业执照" prop="yyzz">
							<el-upload :action="uploadActionUrl" :show-file-list="false" :on-success="handleYyzzSuccess"
								class="upload">
								<div class="img-wrap"><img :src="$img(formData.yyzz)" v-if="formData.yyzz" />
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</el-upload>
						</el-form-item>
						<el-form-item label="授权文件" prop="sqfile">
							<el-upload :action="uploadActionUrl" :show-file-list="false"
								:on-success="handleSqfileSuccess" class="upload">
								<div class="img-wrap"><img :src="$img(formData.sqfile)" v-if="formData.sqfile" />
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</el-upload>
						</el-form-item>
						<el-form-item label="承诺书" prop="cnsfile">
							<el-upload :action="uploadActionUrl" :show-file-list="false"
								:on-success="handleCnfileSuccess" class="upload">
								<div class="img-wrap"><img :src="$img(formData.cnsfile)" v-if="formData.cnsfile" />
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</el-upload>
						</el-form-item>
						<el-form-item label="机构邀请码">
							<el-input v-model.trim="formData.code" autocomplete="off" placeholder="请输入机构邀请码（选填）"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="备注" prop="remark">
							<el-input v-model.trim="formData.remark" type="textarea" autocomplete="off"
								placeholder="请输入备注" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业位置" prop="latitude">
							<el-input v-model.trim="address" autocomplete="off" placeholder="地图位置"
								class="ns-len-input"></el-input>
							<el-button type="primary" size="small" @click="searchMap('ruleForm')">搜索位置</el-button>
							<div id="allmap" class="allmap" />
						</el-form-item>
						<div class="link_wrap">
							<el-link href="http://bgshop.ycgj.site/public/sqfile.docx" target="_blank">下载授权文件样本</el-link>
							<el-link href="http://bgshop.ycgj.site/public/cnsfile.docx" target="_blank">下载承诺书文件样本</el-link>
						</div>
						<el-form-item>
							<el-button type="primary" size="medium" @click="saveAuth('ruleForm')">提交认证信息</el-button>
						</el-form-item>
					</el-form>
					<!-- 认证信息 -->
					<el-form ref="authRef" class="info_wrap" :model="formData" label-width="100px" v-else>
						<el-form-item label="类型:">
							<p>{{ formData.type==1?'物资类企业':formData.type==2?'物流类企业':'服务类企业' }}</p>
						</el-form-item>

						<el-form-item label="所属地区:">
							<p>{{ formData.area_name }}</p>
						</el-form-item>
						<el-form-item label="企业名称:">
							<p>{{formData.name }}</p>
						</el-form-item>
						<el-form-item label="统一代码:">
							<p>{{formData.unicode }}</p>
						</el-form-item>
						<el-form-item label="营业执照:" prop="yyzz">
							<div class="img-wrap"><img :src="$img(formData.yyzz)" />
							</div>
						</el-form-item>
						<el-form-item label="授权文件:" prop="yyzz">
							<div class="img-wrap"><img :src="$img(formData.sqfile)" />
							</div>
						</el-form-item>
						<el-form-item label="承诺书:" prop="yyzz">
							<div class="img-wrap"><img :src="$img(formData.cnsfile)" />
							</div>
						</el-form-item>
						<el-form-item label="机构邀请码:">
							<p>{{formData.code }}</p>
						</el-form-item>
						<el-form-item label="备注:">
							<p>{{formData.remark }}</p>
						</el-form-item>
						<!-- <el-form-item label="企业位置:">
							<p>{{address }}</p>
						</el-form-item> -->
						<el-form-item v-if="authStatus==1">
							<el-button type="primary" size="medium">待审核</el-button>
						</el-form-item>
					</el-form>

				</el-tab-pane>
				<el-tab-pane label="完善信息" name="second" v-if="authStatus==2">
					<el-form :model="formData" :rules="formrules" ref="ruleFormData" label-width="110px">
						<el-form-item label="logo" prop="logo">
							<el-upload :action="uploadActionUrl" :show-file-list="false" :on-success="handleLogoSuccess"
								class="upload">
								<div class="img-wrap"><img :src="$img(formData.logo)" v-if="formData.logo" />
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</el-upload>
						</el-form-item>
						<el-form-item label="法人" prop="legal_person">
							<el-input v-model="formData.legal_person" placeholder="请输入法人"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业曾用名">
							<el-input v-model="formData.name_once" placeholder="请输入企业曾用名"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="工商注册号" prop="register_number">
							<el-input v-model="formData.register_number" placeholder="请输入工商注册号"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="纳税人识别码" prop="taxpayer_code">
							<el-input v-model="formData.taxpayer_code" placeholder="请输入纳税人识别码"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="组织机构代码" prop="organization_code">
							<el-input v-model="formData.organization_code" placeholder="请输入组织机构代码"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="登记机关" prop="register_organ">
							<el-input v-model="formData.register_organ" placeholder="请输入登记机关"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="成立日期" prop="cl_time">
							<el-date-picker v-model="formData.cl_time" value-format="yyyy-MM-dd" type="date"
								placeholder="选择成立日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="核准日期" prop="check_time">
							<el-date-picker v-model="formData.check_time" value-format="yyyy-MM-dd" type="date"
								placeholder="选择核准日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="经营期限" prop="jy_time">
							<el-date-picker unlink-panels v-model="formData.jy_time" type="daterange"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
								value-format="yyyy-MM-dd">
							</el-date-picker>
							<!-- <el-date-picker v-model="formData.jy_time" value-format="yyyy-MM-dd" type="date"
								placeholder="选择经营期限">
							</el-date-picker> -->
						</el-form-item>
						<el-form-item label="注册资本" prop="zczb">
							<el-input v-model="formData.zczb" placeholder="请输入注册资本" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业类型" prop="category_id">
							<el-select v-model="formData.category_id" placeholder="请选择企业类型" @change="categoryChange">
								<el-option v-for="item in categoryList" :key="item.class_id" :label="item.class_name"
									:value="item.class_id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="所属行业" prop="industry_id">
							<el-select v-model="formData.industry_id" placeholder="请选择所属行业" @change="industryChange">
								<el-option v-for="item in industryList" :key="item.class_id" :label="item.class_name"
									:value="item.class_id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="经营范围" prop="jyfw">
							<el-input v-model.trim="formData.jyfw" type="textarea" autocomplete="off"
								placeholder="请输入经营范围" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="联系人" prop="lx_name">
							<el-input v-model="formData.lx_name" placeholder="请输入联系人" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="联系电话" prop="lx_mobile">
							<el-input maxlength="11" v-model="formData.lx_mobile" placeholder="请输入联系电话"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="联系邮箱" prop="lx_email">
							<el-input v-model="formData.lx_email" placeholder="请输入联系邮箱" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业介绍">
							<el-input v-model.trim="formData.content" type="textarea" autocomplete="off"
								placeholder="请输入企业介绍" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="宣传视频" prop="video">
							<el-upload :action="uploadVideoUrl" :on-success="handleVideoSuccess"
								:before-upload="beforeVideoUpload" :file-list="videofileList" :on-remove="handleRemove"
								class="upload">
								<div class="img-wrap" v-if="formData.video==''">
									<i class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</el-upload>
						</el-form-item>
						<el-form-item label="注册地址">
							<el-select v-model="formData.province_id" placeholder="请选择省"
								@change="changeProvice(formData.province_id)">
								<el-option v-for="option in province" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="formData.city_id" placeholder="请选择市"
								@change="changeCity(formData.city_id)">
								<el-option v-for="option in city" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="formData.district_id" placeholder="请选择区/县"
								@change="changeDistrict(formData.district_id)">
								<el-option v-for="option in district" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="详细地址">
							<el-input v-model="formData.address" placeholder="请输入详细地址" class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业标签">
							<el-input v-model.trim="formData.label" autocomplete="off" placeholder="请输入标签（多个使用英文逗号相隔）"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业网站">
							<el-input v-model.trim="formData.website" autocomplete="off" placeholder="请输入企业网站"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业微博">
							<el-input v-model.trim="formData.weibo" autocomplete="off" placeholder="请输入企业微博"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业公众号">
							<el-input v-model.trim="formData.account" autocomplete="off" placeholder="请输入企业公众号"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="企业环境">
							<el-upload list-type="picture-card" :action="uploadActionUrl" :limit="9"
								:on-success="handleHjSuccess" :file-list="hjList" :on-remove="handleHjRemove"
								class="upload" multiple>
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="企业荣誉">
							<el-upload list-type="picture-card" :action="uploadActionUrl" :limit="9"
								:on-success="handleRySuccess" :file-list="ryList" :on-remove="handleRyRemove"
								class="upload" multiple>
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="medium" @click="saveInfo('ruleFormData')">保存信息</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		</div>
		<!-- <el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>企业认证</span>
			</div>

			<div v-loading="loading" class="ns-member-address-list" v-if="authStatus==0||authStatus==1">

			</div>
		</el-card> -->
	</div>
</template>

<script>
	import Config from '@/utils/config';
	import {
		addressInfo
	} from "@/api/member/member"
	// import {
	// 	getArea
	// } from "@/api/address"
	import {
		BMapLoader
	} from '@/utils/bdmap'
	import {
		user_info,
		getArea,
		authCompany,
		companyPerfect,
		companyInfo,
		companyIndustry,
		companyCategory
	} from "@/api/supply/supply"
	export default {
		name: "supplymemberAuthentication",
		components: {},
		data() {
			let self = this
			var isMobile = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"))
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error("请输入正确的手机号"))
					}
				}
			}
			var checkemail = (rules, value, callback) => {
				const regEmail = /^\w+@\w+(\.\w+)+$/
				if (value == '' || regEmail.test(value)) {
					return callback()
				}
				callback(new Error("请输入正确的的邮箱"))
			}
			var areaValidator = (rule, value, callback) => {
				if (self.formData.area_id) {
					return callback()
				} else {
					return callback(new Error("请选择省"))
				}
			}

			return {
				address: "",
				map: null,
				lon: 116.404,
				lat: 39.915,
				activeName: 'first',
				uploadActionUrl: Config.baseUrl + '/companyapi/Company/image',
				uploadVideoUrl: Config.baseUrl + '/companyapi/Company/video',
				authStatus: null,
				formData: {
					longitude: "",
					latitude: "",
					name: "",
					unicode: "",
					code: "",
					area_id: "",
					area_name: "",
					remark: "",
					type: 1,
					yyzz: "",
					cnsfile: "",
					sqfile: "",
					logo: "",
					legal_person: "",
					name_once: "",
					register_number: "",
					taxpayer_code: "",
					organization_code: "",
					register_organ: "",
					jy_time: "",
					jy_start_time: "",
					jy_ent_time: "",
					check_time: "",
					cl_time: "",
					zczb: "",
					category_name: "",
					category_id: "",
					industry_id: "",
					industry_name: "",
					jyfw: "",
					lx_name: "",
					lx_mobile: "",
					lx_email: "",
					content: "",
					video: "",
					province_id: "",
					province_name: "",
					city_id: "",
					city_name: "",
					district_id: "",
					district_name: "",
					address: "",
					label: "",
					website: "",
					weibo: "",
					account: "",
					hj_image: "",
					ry_image: ""
				},
				addressValue: "",
				flag: false, //防重复标识
				defaultRegions: [],
				rules: {
					type: [{
						required: true,
						message: "请选择类型",
						trigger: "blur"
					}],
					name: [{
						required: true,
						message: "请输入企业名称",
						trigger: "blur"
					}],
					yyzz: [{
						required: true,
						message: "请上传营业执照",
						trigger: "blur"
					}],
					sqfile: [{
						required: true,
						message: "请上传授权证书",
						trigger: "blur"
					}],
					cnsfile: [{
						required: true,
						message: "请上传承诺书",
						trigger: "blur"
					}],
					unicode: [{
						required: true,
						message: "请输入统一代码",
						trigger: "blur"
					}],
					area_name: [{
						required: true,
						validator: areaValidator,
						trigger: "blur"
					}],
					latitude: [{
						required: true,
						message: "请选择企业位置",
						trigger: "blur"
					}]
				},
				formrules: {
					logo: [{
						required: true,
						message: "请上传logo",
						trigger: "blur"
					}],
					legal_person: [{
						required: true,
						message: "请输入法人",
						trigger: "blur"
					}],

					register_number: [{
						required: true,
						message: "请输入工商注册号",
						trigger: "blur"
					}],
					taxpayer_code: [{
						required: true,
						message: "请输入纳税人识别码",
						trigger: "blur"
					}],
					organization_code: [{
						required: true,
						message: "请输入组织机构代码",
						trigger: "blur"
					}],
					register_organ: [{
						required: true,
						message: "请输入登记机关",
						trigger: "blur"
					}],
					jy_time: [{
						required: true,
						message: "请选择经营期限",
						trigger: "blur"
					}],
					check_time: [{
						required: true,
						message: "请选择核准日期",
						trigger: "blur"
					}],
					zczb: [{
						required: true,
						message: "请输入注册资本",
						trigger: "blur"
					}],
					cl_time: [{
						required: true,
						message: "请选择成立日期",
						trigger: "blur"
					}],
					category_id: [{
						required: true,
						message: "请选择企业类型",
						trigger: "blur"
					}],
					industry_id: [{
						required: true,
						message: "请选择所属行业",
						trigger: "blur"
					}],
					jyfw: [{
						required: true,
						message: "请输入经营范围",
						trigger: "blur"
					}],
					lx_name: [{
						required: true,
						message: "请输入联系人",
						trigger: "blur"
					}],
					lx_mobile: [{
						required: true,
						validator: isMobile,
						trigger: "blur"
					}],
					lx_email: [{
						validator: checkemail,
						trigger: "blur"
					}]
				},
				province: [],
				city: [],
				district: [],
				pickerValueArray: [],
				multiIndex: [0, 0, 0],
				isInitMultiArray: false,
				// 是否加载完默认地区
				isLoadDefaultAreas: false,
				loading: true,
				yes: true,
				categoryList: [],
				industryList: [],
				// yyzz:""
				ryList: [],
				hjList: [],
				videofileList: []
			}
		},
		created() {
			// this.formData.id = this.$route.query.id
			this.getuserInfo()
			this.getDefaultAreas(0, {
				level: 0
			})
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		watch: {
			defaultRegions: {
				handler(arr, oldArr = []) {
					// 避免传的是字面量的时候重复触发
					if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
					this.handleDefaultRegions()
				},
				immediate: true
			}
		},
		computed: {
			pickedArr() {
				// 进行初始化
				if (this.isInitMultiArray) {
					return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
				}
				return [this.pickerValueArray[0], this.city, this.district]
			}
		},
		methods: {
			searchMap() {
				var myGeo = new BMapGL.Geocoder();
				// 将地址解析结果显示在地图上，并调整地图视野
				let that = this
				myGeo.getPoint(that.address, function(point) {
					console.log(point)
					if (point) {
						that.map.clearOverlays();
						that.map.centerAndZoom(point, 16);
						that.map.addOverlay(new BMapGL.Marker(point, {
							title: that.address
						}))
						that.formData.latitude = point.lat
						that.formData.longitude = point.lng
						that.lat = point.lat
						that.lon = point.lng
					} else {
						alert('您选择的地址没有解析到结果！');
					}
				}, '北京市')
			},
			initMap() {
				this.$nextTick(() => {
					BMapLoader('BaudVlM3HIGrOFosAdXGlRrxuzWU5B2W').then(BMap => {

						const map = new BMap.Map('allmap', {
							enableMapClick: false
						})
						this.map = map
						this.map.disableKeyboard() // 禁用键盘操作地图
						this.map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
						// 添加中心点和缩放等级，如果不添加，初始化出来页面为白屏
						const centerPoint = new BMap.Point(this.lon, this.lat)
						// this.map.addOverlay(new BMap.Marker(centerPoint), );
						// 标注添加到地图
						this.map.centerAndZoom(centerPoint, 9);
						map.addOverlay(centerPoint);
						var markerc = new BMapGL.Marker(new BMapGL.Point(this.lon, this.lat));
						map.addOverlay(markerc);
						var geoc = new BMapGL.Geocoder();
						let that = this
						map.addEventListener('click', function(e) {
							map.clearOverlays();
							console.log(e);
							var pt = e.latlng;
							var marker = new BMapGL.Marker(new BMapGL.Point(pt.lng, pt.lat));
							map.addOverlay(marker);
							geoc.getLocation(pt, function(rs) {
								var addComp = rs.addressComponents;
								that.address = addComp.province + ", " + addComp.city +
									", " + addComp.district + ", " + addComp.street +
									", " + addComp.streetNumber
								console.log(addComp)
								that.formData.latitude = pt.lat
								that.formData.longitude = pt.lng
								that.lat = pt.lat
								that.lon = pt.lng
								// $('#result_l').text(addComp.province + ", " + addComp
								// 	.city + ", " + addComp.district + ", " + addComp
								// 	.street + ", " + addComp.streetNumber);
							})
						})

					})
				})
			},
			handleClick(tab, event) {},
			//上传图片
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.formData.video = ''
			},
			beforeVideoUpload(file) {
				console.log(file)
				const isJPG = file.type.includes('video');

				if (!isJPG) {
					this.$message.error('请上传视频格式!');
				}
				return isJPG;
			},
			handleRyRemove(file, fileList) {
				this.ryList = fileList.map(item => {
					return {
						url: this.$img(item.response.data.pic_path)
					}
				})
				let data = fileList.map(item => {
					return item.response.data.pic_path
				})
				this.formData.ry_image = data.join(',')
			},
			handleRySuccess(res, file, fileList) {
				this.ryList.push({
					url: this.$img(res.data.pic_path)
				})
				let data = fileList.map(item => {
					console.log(item, 123)
					if (item.response) {
						return item.response.data.pic_path
					} else {
						return item.url
					}

				})
				this.formData.ry_image = data.join(',')
				console.log(this.formData.ry_image, this.ryList)
			},
			handleHjRemove(file, fileList) {
				this.hjList = fileList.map(item => {
					return {
						url: this.$img(item.response.data.pic_path)
					}
				})
				let data = fileList.map(item => {
					return item.response.data.pic_path
				})
				this.formData.hj_image = data.join(',')
				// this.hjList = fileList.map(item => {
				// 	return item.response.data.pic_path
				// })
				// this.formData.hj_image = this.hjList.join(',')
			},
			handleHjSuccess(res, file, fileList) {
				this.hjList.push({
					url: this.$img(res.data.pic_path)
				})
				let data = fileList.map(item => {
					console.log(item, 123)
					if (item.response) {
						return item.response.data.pic_path
					} else {
						return item.url
					}

				})
				this.formData.hj_image = data.join(',')
				// this.hjList.push(res.data.pic_path)
				// this.formData.hj_image = this.hjList.join(',')
			},
			handleVideoSuccess(res, file) {
				console.log(res, file)
				this.formData.video = res.data.path;
				// this.yyzz = URL.createObjectURL(file.raw);
			},
			handleLogoSuccess(res, file) {
				this.formData.logo = res.data.pic_path;
				// this.yyzz = URL.createObjectURL(file.raw);
			},
			handleYyzzSuccess(res, file) {
				this.formData.yyzz = res.data.pic_path;
				// this.yyzz = URL.createObjectURL(file.raw);
			},
			handleSqfileSuccess(res, file) {
				this.formData.sqfile = res.data.pic_path;
				// this.yyzz = URL.createObjectURL(file.raw);
			},
			handleCnfileSuccess(res, file) {
				this.formData.cnsfile = res.data.pic_path;
				// this.yyzz = URL.createObjectURL(file.raw);
			},
			/**
			 * 改变省
			 */
			changeProvice(id) {
				this.getAreas(id, data => (this.city = data))
				let obj = {}
				obj = this.province.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.city_id = ""
				this.formData.district_id = ""
				this.formData.province_name = obj.name // 设置选中的地址
			},
			changeArea(id) {
				this.getAreas(id, data => (this.city = data))
				let obj = {}
				obj = this.province.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				// this.formData.city_id = ""
				// this.formData.district_id = ""
				this.formData.area_name = obj.name // 设置选中的地址
			},
			/**
			 * 改变市
			 */
			changeCity(id) {
				this.getAreas(id, data => (this.district = data))
				let obj = {}
				obj = this.city.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.district_id = ""
				this.formData.city_name = obj.name
			},
			/**
			 * 改变区
			 */
			changeDistrict(id) {
				let obj = {}
				obj = this.district.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.district_name = obj.name
			},
			/**
			 * 获取公司信息
			 */
			getCompanyInfo(id) {
				companyInfo({
						id
					})
					.then(res => {
						let data = res.data
						console.log(data)
						if (data != null) {
							this.formData.name = data.name
							this.formData.unicode = data.unicode
							this.formData.code = data.code
							this.formData.area_id = data.area_id
							this.formData.area_name = data.area_name
							this.formData.remark = data.remark
							this.formData.type = data.type
							this.formData.yyzz = data.yyzz
							this.formData.cnsfile = data.cnsfile
							this.formData.sqfile = data.sqfile
							this.formData.reason = data.reason
							this.formData.latitude = data.latitude?data.latitude:""
							this.formData.longitude = data.longitude?data.longitude:""
							if(this.formData.latitude){
								this.lat = this.formData.latitude
								this.lon = this.formData.longitude
								this.initMap()
							}
							// var geoc = new BMapGL.Geocoder();
							// let that = this
							// geoc.getLocation({lat:data.latitude,lng:data.longitude}, function(rs) {
							// 	var addComp = rs.addressComponents;
							// 	that.address = addComp.province + ", " + addComp.city +
							// 		", " + addComp.district + ", " + addComp.street +
							// 		", " + addComp.streetNumber
							// 	// $('#result_l').text(addComp.province + ", " + addComp
							// 	// 	.city + ", " + addComp.district + ", " + addComp
							// 	// 	.street + ", " + addComp.streetNumber);
							// })
							if (this.authStatus == 2) {
								this.formData.logo = data.logo
								this.formData.legal_person = data.legal_person
								this.formData.cl_time = data.cl_time
								this.formData.zczb = data.zczb
								this.formData.category_name = data.category_name
								this.formData.category_id = data.category_id
								this.formData.industry_name = data.industry_name
								this.formData.industry_id = data.industry_id
								this.formData.lx_name = data.lx_name
								this.formData.lx_mobile = data.lx_mobile
								this.formData.lx_email = data.lx_email
								this.formData.jyfw = data.jyfw
								this.formData.content = data.content
								this.formData.video = data.video
								this.videofileList = [{
									name: data.video
								}]
								this.formData.province_id = data.province_id == 0 ? '' : data.province_id
								this.formData.province_name = data.province_name
								this.formData.city_id = data.city_id == 0 ? '' : data.city_id
								this.formData.city_name = data.city_name
								this.formData.district_id = data.district_id == 0 ? '' : data.district_id
								this.formData.district_name = data.district_name
								this.defaultRegions = [data.province_id, data.city_id, data.district_id]
								this.formData.address = data.address
								this.formData.label = data.label.join(',')
								this.formData.website = data.website
								this.formData.weibo = data.weibo
								this.formData.account = data.account
								// this.formData.hj_image = data.hj_image
								this.formData.hj_image = data.hj_image.length > 0 ? data.hj_image.join(',') : ''
								if (this.formData.hj_image) {
									this.hjList = data.hj_image.map(item => {
										return {
											url: this.$img(item)
										}
									})
								} else {
									this.hjList = []
								}
								this.formData.ry_image = data.ry_image.length > 0 ? data.ry_image.join(',') : ''
								if (this.formData.ry_image) {
									this.ryList = data.ry_image.map(item => {
										return {
											url: this.$img(item)
										}
									})
								} else {
									this.ryList = []
								}
								this.formData.name_once = data.name_once
								this.formData.register_number = data.register_number
								this.formData.taxpayer_code = data.taxpayer_code
								this.formData.organization_code = data.organization_code
								this.formData.register_organ = data.register_organ
								this.formData.jy_time = [data.jy_start_time, data.jy_ent_time]
								this.formData.check_time = data.check_time
							}
							console.log(this.formData)
						}
					})
					.catch(err => {})
			},
			categoryChange(e) {
				this.categoryList.forEach(item => {
					if (item.class_id == e) {
						this.formData.category_name = item.class_name
					}
				})
				console.log(e, this.formData)
			},
			industryChange(e) {
				this.industryList.forEach(item => {
					if (item.class_id == e) {
						this.formData.industry_name = item.class_name
					}
				})
			},
			getCompanyType() {
				companyCategory().then(res => {
						let data = res.data
						if (data != null) {
							this.categoryList = data
						}
					})
					.catch(err => {})
			},
			getCompanyIndustry() {
				companyIndustry().then(res => {
						let data = res.data
						if (data != null) {
							this.industryList = data
						}
					})
					.catch(err => {})
			},
			getuserInfo() {

				user_info()
					.then(res => {
						let data = res.data
						if (data != null) {
							this.authStatus = data.is_auth
							this.initMap()
							if (this.authStatus != 0) {
								this.getCompanyInfo(data.company_id)
								if (this.authStatus == 2) {
									this.getCompanyType()
									this.getCompanyIndustry()
								}
							}
						}
					})
					.catch(err => {})
			},
			// 异步获取地区
			getAreas(pid, callback) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							res.data.forEach((item, index) => {
								data.push(item)
							})
							if (callback) callback(data)
						}
					})
					.catch(err => {})
			},

			/**
			 * 获取省市区列表
			 */
			getDefaultAreas(pid, obj) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							var selected = undefined
							res.data.forEach((item, index) => {
								if (obj != undefined) {
									if (obj.level == 0 && obj.province_id != undefined) {
										selected = obj.province_id
									} else if (obj.level == 1 && obj.city_id != undefined) {
										selected = obj.city_id
									} else if (obj.level == 2 && obj.district_id != undefined) {
										selected = obj.district_id
									}
								}

								if (selected == undefined && index == 0) {
									selected = item.id
								}
								data.push(item)
							})

							this.pickerValueArray[obj.level] = data
							if (obj.level + 1 < 3) {
								obj.level++
								this.getDefaultAreas(selected, obj)
							} else {
								this.isInitMultiArray = true
								this.isLoadDefaultAreas = true
							}

							this.province = this.pickerValueArray[0]
						}
						this.loading = false
					})
					.catch(err => {
						this.loading = false
					})
			},

			/**
			 * 渲染默认值
			 */
			handleDefaultRegions() {
				var time = setInterval(() => {
					if (!this.isLoadDefaultAreas) return
					this.isInitMultiArray = false

					for (let i = 0; i < this.defaultRegions.length; i++) {
						for (let j = 0; j < this.pickerValueArray[i].length; j++) {
							this.province = this.pickerValueArray[0]

							// 匹配省
							if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
								// 设置选中省
								this.$set(this.multiIndex, i, j)
								// 查询市
								this.getAreas(this.pickerValueArray[i][j].id, data => {
									this.city = data

									for (let k = 0; k < this.city.length; k++) {
										if (this.defaultRegions[1] == this.city[k].id) {
											// 设置选中市
											this.$set(this.multiIndex, 1, k)

											// 查询区县
											this.getAreas(this.city[k].id, data => {
												this.district = data

												// 设置选中区县
												for (let u = 0; u < this.district.length; u++) {
													if (this.defaultRegions[2] == this.district[u]
														.id) {
														this.$set(this.multiIndex, 2, u)
														this.handleValueChange({
															detail: {
																value: [j, k, u]
															}
														})
														break
													}
												}
											})

											break
										}
									}
								})
							}
						}
					}
					if (this.isLoadDefaultAreas) clearInterval(time)
				}, 100)
			},
			handleDefaultRegionsArea() {

				var time = setInterval(() => {
					if (!this.isLoadDefaultAreas) return
					this.isInitMultiArray = false

					for (let i = 0; i < this.defaultRegions.length; i++) {
						for (let j = 0; j < this.pickerValueArray[i].length; j++) {
							this.province = this.pickerValueArray[0]

							// 匹配省
							if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
								// 设置选中省
								this.$set(this.multiIndex, i, j)
							}
						}
					}
					if (this.isLoadDefaultAreas) clearInterval(time)
				}, 100)
			},
			handleValueChange(e) {
				// 结构赋值
				let [index0, index1, index2] = e.detail.value
				let [arr0, arr1, arr2] = this.pickedArr
				let address = [arr0[index0], arr1[index1], arr2[index2]]

				// this.formData.province_name = ""
				// for (let i = 0; i < address.length; i++) {
				// 	if (this.formData.province_name) {
				// 		this.formData.province_name = this.formData.province_name + "-" + address[i].name
				// 	} else {
				// 		this.formData.province_name = this.formData.province_name + address[i].name
				// 	}
				// }
			},

			/**
			 * 提交认证信息
			 */
			saveInfo(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var data = {
							logo: this.formData.logo,
							legal_person: this.formData.legal_person,
							cl_time: this.formData.cl_time,
							zczb: this.formData.zczb,
							category_name: this.formData.category_name,
							category_id: this.formData.category_id,
							industry_name: this.formData.industry_name,
							industry_id: this.formData.industry_id,
							lx_name: this.formData.lx_name,
							lx_mobile: this.formData.lx_mobile,
							lx_email: this.formData.lx_email,
							jyfw: this.formData.jyfw,
							content: this.formData.content,
							video: this.formData.video,
							province_id: this.formData.province_id,
							province_name: this.formData.province_name,
							city_id: this.formData.city_id,
							city_name: this.formData.city_name,
							district_id: this.formData.district_id,
							district_name: this.formData.district_name,
							address: this.formData.address,
							label: this.formData.label,
							website: this.formData.website,
							weibo: this.formData.weibo,
							account: this.formData.account,
							hj_image: this.formData.hj_image,
							ry_image: this.formData.ry_image,
							name_once: this.formData.name_once,
							register_number: this.formData.register_number,
							taxpayer_code: this.formData.taxpayer_code,
							organization_code: this.formData.organization_code,
							register_organ: this.formData.register_organ,
							jy_start_time: this.formData.jy_time[0],
							jy_ent_time: this.formData.jy_time[1],
							check_time: this.formData.check_time
						}

						if (this.flag) return
						this.flag = true

						companyPerfect(data)
							.then(res => {
								if (res.code == 0) {
									this.flag = false
									this.$message({
										message: "提交成功！",
										type: "success"
									})
									// this.authStatus = 1
								} else {
									this.flag = false
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.flag = false
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			},
			saveAuth(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var data = {
							name: this.formData.name,
							type: this.formData.type,
							area_id: this.formData.area_id,
							area_name: this.formData.area_name,
							name: this.formData.name,
							unicode: this.formData.unicode,
							yyzz: this.formData.yyzz,
							sqfile: this.formData.sqfile,
							cnsfile: this.formData.cnsfile,
							code: this.formData.code,
							remark: this.formData.remark,
							longitude: this.formData.longitude,
							latitude: this.formData.latitude
						}

						if (this.flag) return
						this.flag = true

						authCompany(data)
							.then(res => {
								if (res.code == 0) {
									this.$message({
										message: "提交成功！",
										type: "success"
									})
									this.authStatus = 1
								} else {
									this.flag = false
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.flag = false
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			}
		}
	}
</script>
<style scoped>
	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #11773a;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #11773a;
		background: #11773a;
	}

	/deep/.el-radio__inner:hover {
		border-color: #11773a;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-form-item.is-error .el-input__inner {
		border-color: #11773a;
	}

	/deep/.el-form-item .el-input__inner:focus {
		border-color: #11773a;
	}

	/deep/.el-textarea__inner:focus {
		border-color: #11773a;
	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.info_wrap .el-form-item__label {
		line-height: 20px;
	}

	/deep/.el-tabs__item:hover {
		color: #11773a;
	}

	/deep/.el-upload--picture-card:hover {
		border-color: #11773a;
	}

	/deep/.el-upload--picture-card:focus {
		border-color: #11773a;
	}

	/deep/.el-range-editor.is-active {
		border-color: #11773a;
	}

	/deep/.el-tabs__item.is-active {
		color: #11773a;
	}
</style>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.member-info {
		background: #ffffff;
		padding: 20px;

		.el-tab-pane {
			display: flex;




		}
	}

	.allmap {
		margin-top: 20px;
		width: 500px;
		height: 500px;
	}

	.link_wrap {
		padding: 0 100px 22px;

		a {
			margin-right: 20px;
			text-decoration: underline;
			color: #337ab7;

			&:hover {
				color: #337ab7;
				border-bottom: none;
			}

			&::after {
				content: '';
				width: 0;
			}
		}
	}

	.upload {
		display: flex;
	}

	.el-upload {
		width: 120px;
	}

	.info_wrap {
		.el-form-item {
			margin-bottom: 14px;

			p {
				line-height: 20px;
			}

		}
	}

	.img-wrap {
		width: 120px;
		height: 120px;
		display: block;
		line-height: 120px;
		overflow: hidden;
		border: 1px solid #f1f1f1;
		cursor: pointer;

		img {
			display: inline-block;
		}

		.avatar-uploader-icon {
			font-size: 36px;
			color: #8c939d;
			width: 120px;
			height: 120px;
			line-height: 120px;
			text-align: center;
		}
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.el-card.is-always-shadow,
	.el-card.is-hover-shadow:focus,
	.el-card.is-hover-shadow:hover {
		box-shadow: unset;
	}

	.el-button--primary {
		background-color: $base-supply-color;
		border-color: $base-supply-color;
	}

	.el-card {
		border: 0;
	}

	.ns-len-input {
		width: 350px;
	}

	.el-select {
		margin-right: 10px;
	}
</style>